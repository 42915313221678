import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { TextBasicAlignment, TextFont, TextResetColor, TextTransform, TextWrappingOverflow } from "../../examples/utilities/TextAlignment";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 id="TextAlignment" className="font-weight-bold mt-3">
  Text Alignment
  <a className="header-link text-light text-decoration-none font-weight-normal ml-2" target="TextAlignment" aria-label="anchor"></a>
    </h3>
    <CodeBlock code={TextBasicAlignment} mdxType="CodeBlock">
  Easily realign text to components with text alignment classes.
    </CodeBlock>
    <CodeBlock title="Text Wrapping and Overflow" code={TextWrappingOverflow} mdxType="CodeBlock">
  <p>
    Wrap text with a <code>.text-wrap</code> class.
  </p>
    </CodeBlock>
    <p>
  Prevent text from wrapping with a <code>.text-nowrap</code> class.
    </p>
    <CodeBlock title="Text Transform" code={TextTransform} mdxType="CodeBlock">
  Transform text in components with text capitalization classes.
    </CodeBlock>
    <CodeBlock title="Font Weight and Italics" code={TextFont} mdxType="CodeBlock">
  Quickly change the weight (boldness) of text or italicize text.
    </CodeBlock>
    <CodeBlock title="Monospace" code={`<p className="text-monospace">This is in monospace</p>`} mdxType="CodeBlock">
  <p>
    Change a selection to our monospace font stack with{" "}
    <code>.text-monospace</code>.
  </p>
    </CodeBlock>
    <CodeBlock title="Reset Color" code={TextResetColor} mdxType="CodeBlock">
  <p>
    Reset a text or link’s color with <code>.text-reset</code>, so that it
    inherits the color from its parent.
  </p>
    </CodeBlock>
    <CodeBlock title="Text Decoration" code={`<a href="#" className="text-decoration-none">Non-underlined link</a>`} mdxType="CodeBlock">
  <p>
    Remove a text decoration with a <code>.text-decoration-none</code> class.
  </p>
    </CodeBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      